.btn-primary {
  background-color: #ffa30fff !important;
  border-color: #ffa30fff !important;
  color: #fff !important;
  input[type="radio"], input[type="checkbox"] {
    margin-right: 10px;
  }
}

.btn-primary:disabled {
  background-color: rgb(255, 186, 74) !important;
  border-color: rgb(255, 186, 74) !important;
  color: #fff !important;
}

.btn-primary:hover:not(:disabled) {
  background-color: rgb(209, 129, 0) !important;
  border-color:  rgb(209, 129, 0) !important;
  color: #fff !important;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: rgb(221, 107, 0) !important;
}

.btn-outline-primary {
  color: rgb(221, 107, 0) !important;
  border-color:  rgb(209, 129, 0) !important;
}

.btn-outline-primary:hover:not(:disabled) {
  background-color: rgb(209, 129, 0) !important;
  border-color:  rgb(209, 129, 0) !important;
  color: #fff !important;
}

input[type="file"] {
  height: 45px;
}