.container {
  margin: 39px -15px 24px -15px;

  .hero {
    background: linear-gradient(
      180deg,
      #f08810 0%,
      rgba(253, 172, 23, 0) 86.98%
    );
    display: flex;
    width: 100%;
    padding: 24px 16px 16px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;

    @media (min-width: 769px) {
      padding: 40px 16px 24px 16px;
    }

    > p {
      font-size: 14px;
      color: #fff;
      margin: 0 auto;

      @media (min-width: 769px) {
        width: 100%;
        font-size: 18px;
      }
    }

    .banner {
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      height: 124px;
      padding: 16px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      text-align: left;
      max-width: 500px;

      > img {
        @media (max-width: 320px) {
          width: 110px;
        }
      }

      @media (min-width: 360px) {
        gap: 24px;
      }

      @media (min-width: 600px) {
        margin: 0 auto;
      }

      .info {
        .progressBar {
          width: 160px;
          height: 6px;
          background-color: #e0e0e0;
          border-radius: 10px;

          @media (max-width: 320px) {
            width: 130px;
          }
        }

        .progression {
          background: linear-gradient(180deg, #ed2e45 0%, #ff6073 100%);
          width: 60px;
          height: 6px;
          position: relative;
          bottom: 6px;
          border-radius: 10px;
        }

        > p {
          font-size: 14px;
          line-height: 16px;

          @media (min-width: 769px) {
            font-size: 16px;
            line-height: 18px;
          }

          > span {
            color: #001d7e;
            font-weight: 600;
            line-height: 24px;
          }
        }

        > button {
          border-radius: 6px;
          background: linear-gradient(80deg, #fe690a 0%, #fdac17 100%);
          display: flex;
          width: auto;
          padding: 8px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          color: #fff;
          font-weight: 700;
          height: 32px;
          border: none;
          font-size: 14px;
        }
      }
    }
  }

  .link {
    padding: 24px 16px 16px 16px;

    @media (min-width: 769px) {
      max-width: 400px;
      margin: 0 auto;
    }

    > p {
      color: #757575;
      line-height: 20px;
      font-size: 14px;
      width: 90%;
      margin: 0 auto 16px auto;

      @media (min-width: 769px) {
        margin: 16px auto;
        font-size: 18px;
        line-height: 24px;
      }

      > b {
        color: #212121;
      }
    }

    .containerLink {
      border-radius: 8px;
      border: 1px solid #eee;
      background: #fff;
      display: inline-flex;
      height: 48px;
      padding: 0px 16px;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      flex-shrink: 0;
      align-self: stretch;

      > p {
        color: #757575;
        font-size: 12px;
        margin: 0;

        @media (min-width: 769px) {
          font-size: 16px;
        }
      }

      > span {
        background: var(
          --Secondary-Gradient,
          linear-gradient(80deg, #fe690a 0%, #fdac17 100%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-transform: uppercase;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }

  .social {
    padding: 24px 16px 16px 16px;

    > p {
      font-size: 14px;
      margin: 0;
      background: #fff;
      z-index: 1;
      display: initial;
      position: relative;
      padding: 0 7px;

      @media (min-width: 769px) {
        font-size: 16px;
      }
    }

    .line {
      height: 1px;
      background: #e0e0e0;
      position: relative;
      bottom: 12px;
      z-index: 0;
    }

    .socialButtonsContainer {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      @media (min-width: 769px) {
        width: 100%;
        max-width: 400px;
        margin: 20px auto auto auto;
      }

      > a,
      > button {
        > img {
          width: 40px;
          cursor: pointer;

          @media (min-width: 769px) {
            width: 50px;
          }
        }
      }

      > button {
        border: none;
        background-color: transparent;
        padding: 0;
      }
    }
  }
}
