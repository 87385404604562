@import '../../assets/scss/variables';

.wrapper {
  width: 100%;
  position: relative;
  text-align: center;
  display: flex;
  padding-top: 16px;
}

.withNav {
  padding-top: $header-height + 16px;
}
