.round-hash-icon{
    background-color: #EEEEEE;
    width: 60px;
    height: 60px;
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification-multiplik{
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 25px 20px;
    max-width: 1000px;
    min-width: 500px;
    border-bottom: solid 1px #EEEEEE;
    cursor: pointer;
    transition: all 750ms;
}

.notification-multiplik:hover{
    border-bottom: solid 1px #FE690A;
    transition: all 750ms;
}

@media (max-width: 540px) {
    .notification-multiplik{
        max-width: 100%;
        min-width: 100%;
    }
}

.notification-content{
    display: grid;
    text-align: left;
}

.notification-title{
    color: #2A2B35
}

.notification-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.notification-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}