html, body {
  height: calc(100% - 70px) !important;
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

.content {
  margin-top: 70px;
  background: white;
  height: calc(100vh - 70px);
  overflow-x: hidden;
}

@media (max-width: 1500px) {
  section.component {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}

.form-card {
  margin: 15px 0px;
  padding: 40px;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  @media (max-width: 700px) {
    padding: 5px;
  }
}

.text-primary {
  color: $primary !important;
}

.color-primary {
  color: $primary !important;
}

.background-primary {
  background-color: $secondary !important;
}

.bg-primary {
  background-color: $secondary !important;
}

.logo-small {
  height: 2rem;
}

.header-logo {
  height: 3rem;
}

.modal-large {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

#loginForm {
  width: 100%;
}

.login-form-container {
  width: 100%;
  max-width: 350px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin: 16px;
  padding: 16px;
}

#btn-login {
  width: 100%;
  max-width: 184px;
}

.password-btn a {
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */
  text-align: center;
  color: #00bbf7;
}

.login-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.CalendarDay__selected_span {
  background: #fba90e;
  border: 1px double #fba90e;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  background: #fd7037;
  border: 1px double #fd7037;
}
