.navbar {
  height: 70px;
  justify-content: space-between;
  .nav-action,
  .nav-link {
    color: $primary !important;
    font-size: 1.2rem;
    cursor: pointer;
    font-weight: 600;
  }
}

#basic-navbar-nav {
  background-color: $secondary;
  border-radius: 4px;
  padding: 5px;
  margin-right: auto;
}

.color-yellow{
  color: $primary;

}

.menu-hamburguer{
  font-size: 2rem;
  cursor: pointer;
}


.navbar-light .navbar-toggler{
  border: none;
}
