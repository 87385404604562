@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.wrapper {
  width: 100%;
  position: relative;
  text-align: center;
  display: flex;
  padding-top: $header-height + 16;
}

.pageTitle {
  font-size: 1.5rem;
  line-height: 2rem;
  color: $primary;
  font-weight: 700;
  text-align: left;
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
