.updateCommissionModal {
  padding: 28px;

  > img {
    margin-bottom: 24px;
  }

  h2 {
    color: #2A2B35;
    font-size: 21px;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 24px;
  }

  .item {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: auto;
    width: 95%;

    > p {
      text-align: left;

      > span {
        font-weight: 500;
      }
    }
  }

  .divider {
    background: #EEE;
    width: 100%;
    height: 1px;
    margin-bottom: 19px;
  }

  button {
    display: flex;
    height: 40px;
    width: 100%;
    padding: 16px 8px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(80deg, #FE690A 0%, #FDAC17 100%);
    border: none;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    margin-top: 16px;
  }
}