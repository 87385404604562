.multiplik {
  height: 100%;
  .multiplik-login-container,
  .multiplik-request-password-container,
  .multiplik-new-password-container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    // background: linear-gradient(
    //     90deg,
    //     rgb(251, 206, 89) 0%,
    //     rgb(255, 79, 104) 100%
    // );
    background-image: url("../../assets/images/bg_multiplik_hero.jpg");
    background-size: cover;
    flex-direction: column;
  }

  #loginForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .multiplik-login-form-container,
  .multiplik-request-password-form-container,
  .multiplik-new-password-form-container {
    width: 100%;
    max-width: 300px;
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin: 1em;
    padding: 2em;
    box-sizing: border-box;
    margin-bottom: .3em;
    .form-label {
        font-weight: bold;
        color: #2A2B35;
        margin-left: .8em;
        margin-bottom: 0px;
        background-color: white;
        border-radius: 10px;
        padding: 0px 5px;
        font-size: .9em;
    }
    .form-control {
        border-radius: 10px;
        height: 50px;
        margin-top: -12px;
    }
    .ssui-button {
        border-radius: 4px;
        background-image: linear-gradient(90deg, #fab22f, #fc8c3b);
        border: 0px;
        color: white;
        &.disabled {
            background-image: inherit;
            opacity: 1;
            background-color: #E0E0E0;
            color: white;
        }
        &.first-access-btn {
            background-image: inherit;
            background-color: white;
            color: #fc8c3b;
            border: 1px solid #fc8c3b;
        }
    }
  }

  #btn-login {
    width: 100%;
    max-width: 184px;
  }

  .password-btn a {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: white;
    text-decoration: underline;
  }
}
