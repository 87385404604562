.titleContainer {
    padding: 0 30px;
}

.description {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2em;
    font-size: 14px;
}

.title {
    background: linear-gradient(79.59deg, #FE690A 0%, #FDAC17 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: -10px;
}