$primary: #FFC000;
$secondary: #F8F8F8;
$white: #FFFFFF;
$black: #2a2a2a;
$gray: #888;
$font-dark-color: #212121;
$light-grey: #E0E0E0;

//Breakpoints from bootstrap.
//Start with min-width, for example: media(min-width: $screen-sm)
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1400px;


$header-height: 70px;
