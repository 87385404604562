.logged-in-area {
  max-width: 100%;
  
  .custom-contents-margin {
    margin-top: -50px;
  }

  .contents {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 42px;
    margin-top: 5rem;
    .multiplik-invite-icon {
      background-color: #ffffff;
      border-radius: 50%;
      min-height: 32px;
      min-width: 32px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .multiplik-invite-text {
      color: #212121;
      font-size: 18px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        margin-bottom: 0px;
        margin-left: 1em;
      }
    }

    .multiplik-link {
      background-color: #fff;
      padding: 20px;
      border-radius: 5px;
      border: 1.0793px dashed #E0E0E0;
      display: flex;
      flex-direction: row;
      gap: 10px;

      .url {
        flex-grow: 1;
        display: inline-grid;

        span {
          color: rgba(117, 117, 117, 1);
          font-size: 18px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      button {
        background: linear-gradient(90deg, #fba90e 2.92%, #fd7037 129.87%);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        padding: 0;
        width: unset;
        height: unset;
        border: none;
        font-size: 18px;
        text-transform: uppercase;
        flex-grow: 0;
      }
    }

    .multiplik-link.copied {
      border-color: #ffa842;

      button {
        color: #ffa842;
        background: none;
        -webkit-text-fill-color: unset;
        -webkit-background-clip: unset;
      }
    }

    .multiplik-share-buttons {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 24px;

      .share-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .line {
          flex-grow: 1;
          background-color: #e0e0e0;
          height: 1px;
          color: black;
        }

        h3 {
          color: #212121;
          font-weight: normal;
          font-size: 18px;
          margin: 0;
          padding: 0;
        }
      }

      .share-icons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .share-icon {
          button {
            background-color: #ffffff;
            border-radius: 50%;
            color: #ffa842;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;
            margin: 0;
            border: 1px solid #e0e0e0;
            width: unset;
            height: unset;
            max-height: none;
            max-width: none;

            svg {
              width: 32px;
              height: 32px;
            }
          }
        }
      }
    }

    .multiplik-download-terms a {
      color: #ff4f50;
      font-size: 20px;

      .icon {
        margin-right: 0.5em;
        font-size: 1.2em;
      }
    }

    &.multiplik-invitations {
      gap: 0px;
      
      .table-container {
        width: 100%;
        overflow-x: auto;
        max-height: 300px;
        background-color: white;
        table {
          overflow-x: auto;
          border-collapse: separate;
          width: 100%;
          text-align: left;
          border-spacing: 0px 10px;
          margin-top: -10px; /* correct offset on first border spacing if desired */

          td,
          th {
            padding: 10px;
          }
          tbody tr {
            background-color: #f5f5f5;
          }
          tbody td {
            border-color: white;
            &:first-child {
              border-left-style: solid;
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            }
            &:last-child {
              border-right-style: solid;
              border-bottom-right-radius: 10px;
              border-top-right-radius: 10px;
            }
          }
          .status {
            font-size: 12px;
            padding-bottom: 2px;
            margin-right: 5px;
            &.pendente {
              color: gainsboro;
            }
            &.aprovado {
              color: green;
            }
          }
        }
      }
      .date-filter {
        align-self: center !important;

        @media(max-width: 768px) {
          .SingleDatePicker {
            width: 118px;

            input {
              font-size: 16px;
              text-align: center;
            }
          }

          span {
            position: relative;
            left: 8px;
          }
        }

        .section-title {
          text-align: left;
          margin-bottom: 0px;
        }
      }
      .invitations-result-title {
        color: gray;
        margin-top: 0.5em;
      }
    }

    .status-container {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      gap: 8px;
      padding: 0px;
      max-width: 600px;
      margin: 0 auto;
      justify-content: space-between;
      > div {
        display: flex;
        flex-direction: column;
        text-transform: capitalize;
        flex-shrink: 0;
        border-radius: 8px;
        padding: 0.5em 0px;
        &:first-child {
          position: relative;
          top: 12px;
          @media(max-width: 768px) {
            top: 2px;
          }
        }
        > span {
          font-size: 1.5em;
          font-weight: 700;
          background: linear-gradient(80deg, #FE690A 0%, #FDAC17 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .balance-container {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      > div {
        margin: 0px 0.2em;
        max-width: 300px;
        box-shadow: 1px 2px 5px gainsboro;
        text-transform: capitalize;
        flex: 1;
        flex-shrink: 0;
        border-radius: 8px;
        padding: 0.5em 0px;
        background-color: #fff;
        @media (max-width: 768px) {
          flex: auto;
          width: 100%;
        }
        > span {
          font-size: 1.2em;
          color: #000000;
        }
      }
    }
    
    .styles_withNav__T6xC4 {
      padding-top: -86px;
    }
    .section-title {
      color: #ffa842;
      font-size: 1.2em;
      flex: 0 0 100%;
    }

    .SingleDatePickerInput__withBorder {
      border: 0px;
      border-bottom: 1px solid #dbdbdb;
      background-color: transparent;
    }

    .DateInput,
    .DateInput_input {
      background: transparent;
      color: black;
    }

    .SingleDatePickerInput_calendarIcon_svg {
      fill: #6a6a6a;
    }

    @media (min-width: 425px) {
      .status-container {
        gap: 0px;
        > div {
          border-bottom: 0px;
          > span {
            font-size: 2em;
          }
        }
      }

      .withdraw-section {
        flex: 1;
      }
    }

    @media (min-width: 1220px) {
      &.multiplik-invitations {
        table {
          table-layout: fixed;
        }
      }
    }
  }

  .multiplik-tabs-container {
    display: flex;
    justify-content: space-around;
    background: linear-gradient(90deg, #fba90e 2.92%, #fd7037 129.87%);
    margin-top: -16px;
    width: 100%;
    position: absolute;
    left: 0px;
    color: #bdbdbd;
    padding: 0.5em 0em 0px 0px;
    border-top: 1px solid orange;
    font-size: 1.3em;
  }

  .multiplik-tab {
    flex: 1;
    padding: 0px 0.5em 0.5em 0.5em;
    cursor: pointer;
    color: #FFFFFF;
    
    &.active {
      border-bottom: 5px solid #D26109;
      color: white;
      font-weight: 600;
    }
  }

  .background-second-part {
    background-color: #ffffff;
  }

  .section-referrals-title {
    width: 100%;
    text-align: center;
    line-height: 0.1em;
    margin: 10px 0 0px;
    position: relative;
    font-weight: 400;
    
    span {
      background: #FFFFFF;
      border-radius: 200px;
      box-shadow: 1px 3px 10px gainsboro;
      color: #FE690A;
      font-size: 16px;
      padding: 10px;
      position: relative;
      top: -25px;
    }

    @media(min-width: 768px) {
      font-size: 18px;
    }
  }

  .ssui-modal-header-icon-container {
    width: 100px !important;
    height: 100px !important;
  }

  .ssui-modal-header-text.hasIcon {
    margin-top: 40px !important;
  }

  .info-icon {
    border: 2px solid black;
    border-radius: 50%;
    min-width: 1.2em;
    min-height: 1.2em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      margin: 0px !important;
      padding: 0px !important;
      padding-bottom: 2px;
      font-size: 10px  !important;
    }
  }

  .modal-footer {
    font-size: .8em;
    color: #757575;
    border-top: 1px solid #e0e0e0;
  }

  .status {
    font-size: 12px;
    padding-bottom: 2px;
    margin-right: 5px;
    &.pendente {
      color: gainsboro;
    }
    &.aprovado {
      color: green;
    }
  }

  .ssui-button--small {
    background-color: white;
    box-shadow: 1px 1px 2px gainsboro;
  }

  .background-performance {
    background-color: #F2F2F2;
    margin-top: -40px;
    padding: 20px;
    width: 100%;
  }

  .legend-span {
    background: #FFFFFF;
    border-radius: 200px;
    box-shadow: 1px 3px 10px gainsboro;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    position: relative;
    justify-content: center;
  }

  .performance-style {
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 70%;
    }
  }  
}
