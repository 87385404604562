.container {
    text-align: left;

    @media(min-width: 768px) {
      max-width: 1100px;
      margin: 0 auto;
      padding: 20px 0;
    }

    > img {
      width: 100%;
      border-radius: 8px;
    }

    > div {
      margin: 40px auto;

      h2 {
        color: #ff8f00;
        font-weight: 700;
      }
    }

    a {
      color: #212529;
      text-decoration: underline;
    }
}