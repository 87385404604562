.table-container {
    overflow-x: auto;
    max-width: 100%; 
  
    table {
      width: 100%;
      border-collapse: collapse;
      background-color: #fff; 
      border-radius: 4px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  
      th, td {
        padding: 12px;
        text-align: left;
  
        span {
          display: inline-block;
          vertical-align: middle;
        }
      }
  
      th {
        background-color: #f5f5f5;
        font-weight: bold;
      }
  
      tr {
        &:hover {
          background-color: #f2f2f2; 
        }
      }
  
      th, td {
        border-bottom: 1px solid #e0e0e0; 
      }
  
      th:first-child, td:first-child {
        padding-left: 20px; 
      }
  
      th:last-child, td:last-child {
        padding-right: 20px; 
      }
    }
  }
  