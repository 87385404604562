@import '../../assets/scss/variables';

.card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  border-radius: 20px;
  background-color: #fff;
  height: 100%;

  box-shadow: 0 0 13.5135px rgb(0 0 0 / 5%);
}

.halfTop {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 168px;
  padding: 52px 16px 16px 16px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  background-color: #ed2e45;
}

.halfBottom {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  color: $font-dark-color;
  text-align: left;
}

.title {
  width: 100%;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.item {
  width: 100%;
  display: flex;

  img {
    margin: 4px 0;
  }
}

.itemDescription {
  padding-left: 4px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.buttonWrapper {
  justify-self: flex-start;
  margin: 16px;
}
