@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css);
@import "~bootstrap/scss/bootstrap.scss";
@import '~react-notifications/lib/notifications.css';
@import "~react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
@import "~react-dates/lib/css/_datepicker.css";
@import "_variables";
@import "_main";
@import "_notification";
@import "_header";
@import "_buttons";
