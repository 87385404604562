.container {
  margin: 43px -15px 24px -15px;

  .filter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    height: 48px;
    margin-bottom: 24px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
    
    p {
      font-size: 14px;
      margin: 0;
      cursor: pointer;

      @media(min-width: 768px) {
        font-size: 16px;
      }
    }
  }

  .indicationsFilters {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    position: absolute;
    background: #fff;
    z-index: 1000;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
    top: 100px;
    left: 90px;
    border-radius: 8px;
    font-size: 14px;

    @media(min-width: 768px) {
      font-size: 16px;
      left: 50%;
      max-width: 300px;
    }

    > span {
      cursor: pointer;
      padding: 7px 12px;

      &:hover {
        background-color: #eee;
      }
    }
  }

  .performance {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 0 15px;

    @media(min-width: 768px) {
      max-width: 500px;
      margin: 48px auto;
      gap: 32px;
    }

    > div {
      cursor: pointer;
      width: 28%;
      box-sizing: border-box;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      > p {
        color: #414141;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 4px;
        line-height: 23px;

        @media(min-width: 768px) {
          font-size: 29px;
          line-height: 34px;
        }
      }

      > span {
        color: #757575;
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        line-height: 14px;

        @media(min-width: 768px) {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }

  .balance {
    padding: 0 15px;
    
    > div {
      border-radius: 8px;
      border: 1px solid #EEE;
      padding: 16px 8px;
      height: 32px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: auto;
      margin-bottom: 16px;
      cursor: pointer;

      @media(min-width: 768px) {
        margin-bottom: 0px;
      }

      > span {
        font-weight: 500;
      }
    }
  }

  .indications {
    .divider {
      height: 2px;
      width: 100%;
      background-color: #eeeeee;

      @media(min-width: 768px) {
        margin: 32px 0;
      }
    }

    .header {
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 24px;
      font-size: 14px;
      font-weight: 500;

      @media(min-width: 768px) {
        max-width: 800px;
        margin: 0 auto;
        font-size: 16px;
      }

      .title {
        > span {
          color: rgb(117,117,117);
        }
      }

      .legend {
        border-radius: 200px;
        border: 1px solid #EEE;
        background: #FFF;
        padding: 10px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .list {
      padding: 0 15px;
      max-height: 210px;
      overflow-y: scroll;

      @media(min-width: 768px) {
        max-width: 800px;
        margin: 0 auto;
        max-height: 230px;
      }

      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        background: rgba(245, 245, 245, 0.93);
        height: 38px;
        padding: 15px;
        font-size: 14px;
        margin-bottom: 5px;

        @media(min-width: 768px) {
          font-size: 16px;
          height: 42px;
        }

        > div {
          display: flex;
          align-items: center;
          gap: 16px;

          .status {
            background-color: #00BA88;
            width: 8px;
            height: 8px;
            border-radius: 50%;
          }
        }

        p {
          margin: 0;
        }
      }
    }

    .viewMore {
      border-radius: 18px;
      background-color: #ffffff;
      display: flex;
      width: 28px;
      height: 38px;
      justify-content: center;
      align-items: center;
      margin: 14px auto auto auto;
      filter: drop-shadow(0px 0px 5px #E0E0E0);
    }

    .viewMoreDescription {
      color: #757575;
      font-size: 14px;
      margin-top: 12px;
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  width: 100%;
}

.statusDot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: relative;
  top: 4px;
  margin-right: 4px;
}