.container {
  max-width: 100%;
  
  .userImage {
    background: linear-gradient(79.59deg, #FE690A 0%, #FDAC17 100%);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    position: relative;
    right: 11px;

    > img {
      max-width: 16px;
    }
  }

  h2 {
      color: #FFA842;
    font-weight: 700;
    font-size: 21px;
    letter-spacing: 0.5px;
  }
}

.progress {
  border: 3px solid #BDBDBD;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  transform: rotate(-44deg);
  margin-right: -30px;
}

.progressBorder1 {
  border-right: 3px solid #FFA842;
}

.progressBorder2 {
  border-right: 3px solid #FFA842;
  border-bottom: 3px solid #FFA842;
}

.progressBorder3 {
  border-right: 3px solid #FFA842;
  border-bottom: 3px solid #FFA842;
  border-left: 3px solid #FFA842;
}

.progressBorder4 {
  border-right: 3px solid #FFA842;
  border-bottom: 3px solid #FFA842;
  border-left: 3px solid #FFA842;
  border-top: 3px solid #FFA842;
}

