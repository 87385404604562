.header {
  background: linear-gradient(79.59deg, #fe690a 0%, #fdac17 100%);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 67px;
  padding: 16px;
  position: absolute;
  z-index: 1200;
  top: 0;
  width: 100%;
  color: #ffffff;

  > p {
    font-weight: 700;
    font-size: 16px;
    position: relative;
    top: 8px;
  }
}

.content {
  @media (min-width: 768px) {
    max-width: 350px;
    margin: 0 auto;
  }

  > div:nth-child(1) {
    min-height: 80px;
  }

  p {
    font-size: 16px;
  }
}

.newPasswordContainer {
  padding: 20px;

  @media (min-width: 768px) {
    max-width: 350px;
    margin: 0 auto;
  }

  > p:nth-child(1) {
    font-weight: 700;
    font-size: 19px;
    line-height: 28px;
  }

  > p:nth-child(2) {
    color: #757575;
    font-size: 16px;
    margin-bottom: 30px;
  }

  .eyeIcon {
    color: #989898;
    background-color: #ffffff;
    margin-left: -18px;
    position: relative;
    right: 10px;
    cursor: pointer;
    width: 20px;
  }

  .label {
    font-size: 14px;
    margin-bottom: 4px;
  }
}

.headerImage {
  margin-top: 15px;
  text-align: center;
  margin-left: calc(50% - 55px/ 2);
}

.headerModalTitle {
  text-align: center;
  color: #fdac17;
  font-weight: 700;
}
