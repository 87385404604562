@import "../../assets/scss/variables";

.button {
  width: 100%;
  background: linear-gradient(90deg, #fba90e 2.92%, #fd7037 129.87%) !important;
  color: #ffffff !important;
  border-radius: 8px;
  padding: 8px 16px;
  min-height: 40px;
  margin: 0;
  text-transform: none;
  border: none;

  font-size: 1rem;
  font-weight: 700;

  @media (max-width: $screen-md) {
    height: 48px;
  }

  &:hover,
  &:active,
  &:focus {
    border: none;
    background: linear-gradient(90deg, #fab22f, #ffa30f) !important;
    opacity: 1 !important;
  }
}

.buttonRounded {
  border-radius: 30px;
}
