@import '../../assets/scss/variables';

.navigatonBar {
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  flex-direction: row;
  justify-content: space-between;
  top: 0;
  height: $header-height;
  background: linear-gradient(90deg, #fba90e 2.92%, #fd7037 129.87%);
  color: #fff;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigationTitle {
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: 0;

  text-transform: uppercase;
  flex: 1;
  text-align: center;
}

.icon {
  height: 100%;
  font-size: 1.5rem;
  align-self: center;
  left: 0;
  cursor: pointer;
}

@media (min-width: $screen-sm) {
  .icon {
    font-size: 2rem;
  }

  .navigationTitle {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}

@media (min-width: $screen-md) {
  .navigationTitle {
    font-size: 1.5rem;
    line-height: 3rem;
  }
}
