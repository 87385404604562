.container {
  .header {
    min-height: 218px;
    background: #eeeeee;
    border-radius: 16px 16px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 12px;

    .dots {
      display: flex;
      gap: 8px;
      margin-top: 12px;

      > span {
        background: #bdbdbd;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }
  }

  .content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 288px;

    button {
      font-size: 20px;
      padding: 10px;
    }

    h3 {
      color: #2a2b35;
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 12px;
      text-align: left;
    }

    p {
      color: #757575;
      font-size: 14px;
      margin-bottom: 50px;
      text-align: left;
    }

    
    span {
      color: #fe690a;
      padding-top: 10px;
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      cursor: pointer;
    }
    
    strong {
      color: #FE690A;
    }
  }
}
