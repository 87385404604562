.container {
  text-align: left;
  padding-bottom: 40px;

  @media(min-width: 768px) {
    width: 1100px;
    margin: 0 auto;
  }

  > h2 {
    font-size: 16px;
    margin: 0 0 15px 0;
    font-weight: 700;
    color: #2A2B35;

    @media(min-width: 768px) {
      font-size: 28px;
    }
  }

  .subtitle {
    font-size: 14px;
  
    > span {
      color: #FE690A;
      font-weight: 700;
    }
  }

  .form {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
    padding: 18px;

    .formControl {
      margin: 15px 0;
      display: flex;
      flex-direction: column;

      > label {
        font-size: 14px;
        font-weight: 700;
        background-color: #ffffff;
        padding: 2px;
        margin-bottom: -14px;
        z-index: 2;
        max-width: fit-content;
        margin-left: 14px;

        > span {
          color: #fdac17
        }
      }

      > input, > select {
        border-radius: 10px;
        border: 1px solid #C5C7D4;
        background: #FFF;
        min-height: 50px;
        padding: 0 15px;

        &:disabled {
          background: #f2f2f2;
        }
      }
    }

    > button {
      border-radius: 8px;
      background: linear-gradient(45deg, #FE690A 0%, #FDAC17 100%);
      color: #ffffff;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 284px;
      height: 44px;
      padding: 16px 8px;
      margin: 0 auto;
      margin-top: 50px;
      font-weight: 700;
      font-size: 18px;

      @media(max-width: 768px) {
        max-width: 100%;
      }
    }
  }
}
