.headerImage { 
  width: 100%;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.buttonFirst {
  width: 100%;
  background: linear-gradient(90deg, #fba90e 2.92%, #fd7037 129.87%) !important;
  color: #ffffff !important;
  border-radius: 8px;
  padding: 8px 16px;
  min-height: 40px;
  margin: 0;
  text-transform: none;
  border: none;

  font-size: 1rem;
  font-weight: 700;

}

.customWhatsappIcon {
  margin-right: 8px;
  margin-bottom: 2px;
  width: 28px;
  height: 28px;
}

.container {
  .header {
    min-height: 218px; 
    background: #eeeeee;
    border-radius: 16px 16px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 12px;

    .dots {
      display: flex;
      gap: 8px;
      margin-top: 12px;

      > span {
        background: #bdbdbd;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }
  }

  .content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2 {
      color: #2a2b35;
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 12px;
    }

    p {
      color: #757575;
      font-size: 14px;
      margin-bottom: 50px;
    }

    span {
      color: #fe690a;
      text-align: center;
      cursor: pointer;
    }
  }
}
