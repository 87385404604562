.backgroundImpact {
  background-color: #F5F5F5;
  margin-top: 25px;
  margin-bottom: -74px;

  .impactFlexContainer {
    display: flex;
    max-width: 1100px;
    margin: 0 auto;
    
    @media (max-width: 1240px) {
      flex-direction: column;
    }
    
    .impactBox {
      flex: 1;
      padding: 20px;
      text-align: center;
      font-size: 18px;
  
      @media(max-width: 768px) {
        padding-top: 0;
      }
  
      .impactCard {
        background-color: #FFFFFF;
        border: 1px solid #ddd;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0 ,0.1);
        height: 305px;
        margin: 50px;
        padding: 16px;
        border-radius: 10px;
        overflow: hidden;
        width: 400px;
        position: relative;
        bottom: 35px;
      
        @media (max-width: 1240px) {
          width: 80%;
          margin: 50px auto;
          min-height: 340px;
        }
      
        @media (max-width: 415px) {
          width: 100%;
        }
      
        @media (max-width: 285px) {
          height: 10%;
        }
      }
  
      .impactImage {
        position: relative;
        width: 586px;
        top: 40px;
        @media (max-width: 1024px) {
          top: -54px;
          @media (max-width: 768px) {
            width: 100%;
            height: 100%;
          }
        }
      }
  
      .impactImageOnline {
        position: relative;
        width: 586px;
        top: 66px;
      
        @media (max-width: 768px) {
          width: 100%;
          height: 100%;
          top: -54px;
        }
      }
  
      .colorImpactText {
        color: #010E39;
        font-size: 28px;
      
        @media (max-width: 768px) {
          font-size: 100%;
        }
      }
  
      .squareBracketsImpact {
        color: #010E39;
        font-size: 80px;
        font-weight: 800;
      
        @media (max-width: 768px) {
            font-size: 50px;
        }
      }    
    }
  }
}

.differentialsFlexContainer {
  display: flex;
  max-width: 1100px;
  flex-wrap: wrap;
  margin: 0 auto;
  
  .differentialsItemsBox {
    padding: 20px;
    text-align: center;
    font-size: 18px;
    width: 25%;
    @media (max-width: 768px) {
      width: 50%;
    }
  }

  .differentialsItemsBoxOnline {
    padding: 20px;
    text-align: center;
    font-size: 18px;
    width: 33%;

    @media (max-width: 768px) {
      width: 50%;
    }
  } 
}

.partnersFlexContainer {
  display: flex;
  max-width: 1100px;
  margin: 0 auto;
  @media (max-width: 1035px) {
    flex-direction: column;
  }
  
  .partnersBox {
    flex: 1;
    padding: 20px;
    text-align: center;
    font-size: 18px;
  } 
}

.multiplikBarFirstText {
  color: #FFC000;
  font-size: 24px;
  font-weight: bold;
  position: relative;

  @media (max-width: 768px) {
    font-size: 70%;
  }
}

.multiplikBarSecondText {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: bold;
  position: relative;

  @media (max-width: 768px) {
    font-size: 70%;
  }
}

.colorPointText {
  color: #FFC000;
  font-size: 28px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 100%;
  }
}

.backgroundMultiplikBar {
  background-color: #010E39;
  padding: 8px 0px 8px 0px;
}

.backgroundDifferentials {
  background-color: #FE690A;
  padding: 64px 0px 64px 0px;
  color: #FFFFFF;
}

.backgroundPartners {
  background-color: #010E39;
  padding: 64px 0px 64px 0px;
  color: #FFFFFF;
}

.benefitItemImage {
  width: 90px;
  height: 90px;
}

.gradientText {
  background-image: linear-gradient(45deg, #FE690A, #FDAC17);
  background-clip: text;
  color: transparent;
  display: inline-block;
}

h1.gradientText{
  font-size: 80px;
  font-weight: 800;
}

.partnerCard {
  background-color: #FFFFFF;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0 ,0.1);
  padding: 16px;
  width: 300px;
  height: 264px;
  margin: auto;
  overflow: hidden;
  
  @media (max-width: 768px) {
    width: 90%;
    height: 80%;
    text-align: center;
  }
}

.cardImage {
  width: 100%;
  height: auto;
}

.cardImagePegn {
  width: 120px;
  height: 64px;
}

.cardText {
  color: #2A2B35;
  line-height: 24px;
  size: 16px;
}

.cardText a {
  color: #FDAC17;

}

.diferentialsFormatText {
  text-transform: uppercase;
  letter-spacing: 6px;
}

h2 {
  color: #FFFF00;
}

.marginCheckBox {
  margin-right: 5px;
}

.labelCheckBox {
  line-height: .5em;
  margin-top: 16px;

  @media (max-width: 1235px) {
    margin-top: 28px
  } 

  @media (max-width: 768px) {
    margin-top: 16px
  } 
}

.spanCheckBox {
  font-weight: 400;
  font-size: 10px;
  text-align: left;
}

.spanDifferentialsColor {
  color: #FFFF00;
}

.centerText {
  text-align: center
}

input {
  border: 1px solid #B8B8B8;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  margin: 1rem 0;
  outline: 0;
}

@media (max-width: 768px) {

  h1.gradientText {
    font-size: 40px;
  }

  .impactFlexContainer .impactBox {
    padding: 15px;
  }

  .fontSizeFold {
    font-size: 15px;
  }
}
