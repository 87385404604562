@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.sidebar-wrapper {
  color: #fff;
  display: block;
  font-size: 0.625rem; //10px;
}

.sidebar-wrapper--active {
  width: 100%;
  position: absolute;
  top: 70px;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all ease;
  z-index: 10;
}

.sidebar-wrapper--active .sidebar {
  right: 0px;
}

.sidebar {
  width: 300px;
  height: 100%;
  position: fixed;
  top: 0;
  right: -400px;
  padding: $header-height 0px 0px 0px;
  overflow: auto;
  background: $secondary;
  transition: all 0.5s ease;
  z-index: 10;
}

.sidebar__menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style: none;
  color: $font-dark-color;
}

.sidebar__menu-header {
  margin-top: 15px;
  min-height: 90px;
  padding: 0 10px 10px 10px;
  font-weight: 300;
}

.sidebar__menu-header > div:nth-child(1) p {
  font-size: 12px;
}

.sidebar__menu-header > div span {
  font-weight: 400;
}

.sidebar__menu-header > div:nth-child(2) {
  padding: 0 16px;
  margin-top: 15px;
  font-size: 11px;
  width: 100%;
}

.sidebar__menu-item {
  border-top: 1px solid $light-grey;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 1px;
  cursor: pointer;
}

.sidebar__menu-item:hover,
.sidebar__menu-item:focus,
.sidebar__menu-item:active {
  background-color: $light-grey;

  a {
    color: $font-dark-color;
  }
}

.sidebar__menu-item > a {
  background-color: white;
  padding: 0 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $font-dark-color;
  text-decoration: none !important;
  text-align: left;

  cursor: pointer;
}

.sidebar__menu-item text {
  width: 100%;
  padding: 4px;
}

.sidebar__menu-item-text-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: left;
}

.sidebar__menu-badge {
  width: 30%;
  padding: 4px;
  border-radius: 67px;
  color: #fff;
  background: linear-gradient(90deg, #ffc000 -0.1%, #ffa842 99.9%);
  text-align: center;
}

.edit-data-button {
  align-self: flex-end;
  border: none;
  border-radius: 8px;
  background: linear-gradient(90deg, #FE690A -0.1%, #FDAC17 99.9%);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
  color: #FFF;
  height: 32px;
  width: 94px;
  margin-top: 1.2rem;
  margin-left: 2rem;
  position: absolute;
  right: 25px;
  right: 25px;
  @media(max-width: 280px) {
    font-size: 10px;
    margin-top: 20px;
    margin-left: 10px;
    width: 100%;
  }
}

.influencer-details {
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  margin: 0 auto;
  padding: 5px;
  width: 276px;
  @media(max-width: 280px) {
    width: 100%;
  }
}

.influencer-box {
  line-height: 6px;
  margin-top: 6px;
}

.label-new {
  background: #B6FFED;
  border-radius: 12px;
  color: #00BA88;
  font-weight: 700;
  font-size: 12px;
  font-style: italic;
  padding: 6px;
  margin-left: 1rem;
  letter-spacing: 1px;
}

#logout-button {
  border-bottom: 1px solid #E0E0E0;
}


@media (min-width: $screen-md) {
  .sidebar {
    width: 400px;
    right: -400px;
  }
}