.headerBackground {
  position: relative;
  width: 100%;
  height: 100%;
}

.headerButtonImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
}

.headerImage {
  width: 100%;
  border-radius: 16px 16px 0 0;
}

.customWhatsappIcon {
  margin-right: 8px;
  margin-bottom: 2px;
  width: 28px;
  height: 28px;
}

.container {
  .header {
    min-height: 218px;
    background: #eeeeee;
    border-radius: 16px 16px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 12px;

    .dots {
      display: flex;
      gap: 8px;
      margin-top: 12px;

      > span {
        background: #bdbdbd;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }
    }
  }

  .content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 288px;

    button {
      margin-top: 20px;
      font-size: 16px;
      padding: 12.5px;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h3 {
      color: #2a2b35;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 12px;
      text-align: left;
    }

    p {
      color: var(--dark-grey, #2A2B35);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      text-align: left;

      span{
        font-size: 16px;
        color: #FE690A;
      }
    }


    span {
      color: #fe690a;
      padding-top: 10px;
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      cursor: pointer;
    }
  }
}

